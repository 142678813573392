import React from "react";
import MyRouter from "routers/index";
import mswImg from "images/msw_logo_small.png";
import ReactGA from 'react-ga4';

ReactGA.initialize([
  {
    trackingId: 'G-5BFNMVQCXL',
  }
]);

function App() {
  let addClass = "";
  if (window.location.pathname === "/") {
    addClass = "bg-concept1 bg-center bg-cover"
  }

  return (
    <div className={addClass + "bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200"}>
      <MyRouter />
    </div>
  );
}

export default App;
